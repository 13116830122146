<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-full space-y-7">
      <h1 class="text-2xl font-semibold">Thông báo</h1>
      <div class="card p-5">
        <div class="">
          <ul>
            <li class="" v-for="(notification, index) in notifications.data" :key="'notification' + index">
              <a href="javascript:void(0)" v-on:click="goToNotification(notification)"
                 class="relative flex py-3 hover:bg-gray-100 rounded-md px-2 mb-2 space-x-4"
                 :class="{'bg-gray-100': notification.is_read === 0}">
                <div class="h-12 w-12 flex-shrink-0">
                  <img
                      :src="notification.sender && notification.sender.avatar ? notification.sender.avatar.url : notification.sender ? 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg' : 'https://static.bcdcnt.net/assets/images/bcdcnt-app-logo.png'"
                      class="rounded-full object-cover h-full w-full">
                </div>
                <span class="bg-blue-500 absolute mt-8 ml-7 text-white p-2 rounded-full w-7 h-7 flex items-center">
                  <i class="icon-feather-heart" v-if="notification.action === 'love_comment'"></i>
                  <i class="icon-feather-at-sign" v-if="notification.action === 'mention'"></i>
                  <i class="icon-feather-plus" v-if="notification.action === 'reward_point'"></i>
                  <i class="far fa-hand-paper" v-if="notification.action === 'process_comment_request'"></i>
                  <i class="far fa-comment" v-if="notification.action === 'comment'"></i>
                  <i class="far fa-envelope" v-if="notification.action === 'ticket_created'"></i>
                </span>
                <div class="flex-1">
                  <p>
                    <strong>{{ notification.sender ? notification.sender.username : "Bạn" }}</strong>
                    {{ notification.content }}
                  </p>
                  <time>{{ timeago(notification.created_at) }}</time>
                </div>
              </a>
            </li>
          </ul>

          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="notifications.paginatorInfo.lastPage > 1"
                v-model="notifications.paginatorInfo.currentPage"
                :pages="notifications.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadNotifications"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {getNotificationRouteName, getNotificationRouteParams, getNotificationRouteQuery, timeago} from "../../core/services/utils.service";

export default {
  name: "NotificationList",
  components: {VPagination},
  data() {
    return {
      notifications: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadNotifications() {
      let query = `query($page: Int) {
          me {
            notifications(first: 10, page: $page, orderBy: [{column: "created_at", order:DESC}]){
              data {
                code
                content
                action
                is_read
                created_at
                sender {
                  username
                  id
                  avatar {
                    url
                  }
                }
                extra
              }
              paginatorInfo {
                currentPage
                lastPage
                total
              }
            }
          }
        }`;

      ApiService.graphql(query, {page: this.notifications.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.me) {
              this.notifications = data.data.me.notifications;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    timeago(x) {
      return timeago(x);
    },
    goToNotification(notification) {
      if (!notification.is_read) {
        notification.is_read = 1;
        this.markAsRead(notification);
      }

      let extra = JSON.parse(notification.extra);

      if (extra.router) {
        let name = extra.router.name ? extra.router.name : getNotificationRouteName(extra.router.type);
        let params = getNotificationRouteParams(extra.router.type, extra.router);
        let query = getNotificationRouteQuery(notification, extra);

        this.$router.push({
          name: name,
          params: params,
          query: query
        });
      }
    },
    markAsRead(notification) {
      let query = `mutation($code: String!) {
        markAsRead(code: $code)
      }`;

      ApiService.graphql(query, {code: notification.code})
          .then(({data}) => {
            if (data.data && data.data.markAsRead) {
              this.loadNotifications();
            } else {
              notification.is_read = 0;
            }
          });
    }
  },
  mounted() {
    this.loadNotifications();
  }
}
</script>
